<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New Buy A Restaurant Business</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputtitle" class="col-sm-2 col-form-label">Title (Thai)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.title" @blur="validationRule()"
										class="form-control" id="inputtitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputSubTitle" class="col-sm-2 col-form-label">Sub Title (Thai)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.subTitle" @blur="validationRule()"
										class="form-control" id="inputSubTitle">
									<small class="text-danger">{{validationData.subTitle}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDescription" class="col-sm-2 col-form-label">Description (Thai)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
                                    <textarea class="form-control" id="inputDescription" v-model="dataRequest.description" @blur="validationRule()"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEnTitle" class="col-sm-2 col-form-label">Title (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.en_title" @blur="validationRule()"
										class="form-control" id="inputEnTitle">
									<small class="text-danger">{{validationData.en_title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEnSubTitle" class="col-sm-2 col-form-label">Sub Title (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.en_subTitle" @blur="validationRule()"
										class="form-control" id="inputEnSubTitle">
									<small class="text-danger">{{validationData.en_subTitle}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEnDescription" class="col-sm-2 col-form-label">Description (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
                                    <textarea class="form-control" id="inputEnDescription" v-model="dataRequest.en_description" @blur="validationRule()"></textarea>
									<small class="text-danger">{{validationData.en_description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label"> Image1 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage1">
									<img v-if="dataRequest.image1" v-bind:src="image1" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image1}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label"> Image2 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage2">
									<img v-if="dataRequest.image2" v-bind:src="image2" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image2}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label"> Image3 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage3">
									<img v-if="dataRequest.image3" v-bind:src="image3" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image3}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label"> Image4 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage4">
									<img v-if="dataRequest.image4" v-bind:src="image4" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image4}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'clientCreate',
		metaInfo: {
            title: "Clent Create",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				image1: "",
				image2: "",
				image3: "",
				image4: "",
				dataRequest: {
					title: "",
					subTitle: "",
					description: "",
					en_title: "",
					en_subTitle: "",
					en_description: "",
					image1: "",
					image2: "",
					image3: "",
					image4: ""
				},
				validationData: {
					title: "",
					subTitle: "",
					description: "",
					en_title: "",
					en_subTitle: "",
					en_description: "",
					image1: "",
					image2: "",
					image3: "",
					image4: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				buyHospitalityStoreAction: 'buyHospitalityStoreAction',
			}),
			readImage1(event) {
				this.dataRequest.image1 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image1 = e.target.result
					this.validationRule()
				};
			},
			readImage2(event) {
				this.dataRequest.image2 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image2 = e.target.result
					this.validationRule()
				};
			},
			readImage3(event) {
				this.dataRequest.image3 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image3 = e.target.result
					this.validationRule()
				};
			},
			readImage4(event) {
				this.dataRequest.image4 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image4 = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('title', this.dataRequest.title); 
				formData.append('subTitle', this.dataRequest.subTitle); 
				formData.append('description', this.dataRequest.description); 
				formData.append('en_title', this.dataRequest.en_title); 
				formData.append('en_subTitle', this.dataRequest.en_subTitle); 
				formData.append('en_description', this.dataRequest.en_description); 
				formData.append('image1', this.dataRequest.image1); 
				formData.append('image2', this.dataRequest.image2); 
				formData.append('image3', this.dataRequest.image3); 
				formData.append('image4', this.dataRequest.image4); 

				await this.buyHospitalityStoreAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'buy-hospitality-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', title: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.dataRequest.subTitle == "") {
					this.validationData.subTitle = "The sub title field is required."
					isValidation.push(true)
				} else {
					this.validationData.subTitle = ""
					isValidation.push(false)
				}
				if (this.dataRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				if (this.dataRequest.en_title == "") {
					this.validationData.en_title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_title = ""
					isValidation.push(false)
				}
				if (this.dataRequest.en_subTitle == "") {
					this.validationData.en_subTitle = "The sub title field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_subTitle = ""
					isValidation.push(false)
				}
				if (this.dataRequest.en_description == "") {
					this.validationData.en_description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_description = ""
					isValidation.push(false)
				}
				if (this.dataRequest.image1 == "") {
					this.validationData.image1 = "The image 1 field is required."
					isValidation.push(true)
				} else {
					this.validationData.image1 = ""
					isValidation.push(false)
				}
				if (this.dataRequest.image2 == "") {
					this.validationData.image2 = "The image 2 field is required."
					isValidation.push(true)
				} else {
					this.validationData.image2 = ""
					isValidation.push(false)
				}
				if (this.dataRequest.image3 == "") {
					this.validationData.image3 = "The image 3 field is required."
					isValidation.push(true)
				} else {
					this.validationData.image3 = ""
					isValidation.push(false)
				}
				if (this.dataRequest.image4 == "") {
					this.validationData.image4 = "The image 4 field is required."
					isValidation.push(true)
				} else {
					this.validationData.image4 = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>